import { render, staticRenderFns } from "./MobileAppCarousel.vue?vue&type=template&id=1c6c70e5&scoped=true&"
import script from "./MobileAppCarousel.vue?vue&type=script&lang=ts&"
export * from "./MobileAppCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./MobileAppCarousel.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./MobileAppCarousel.vue?vue&type=style&index=1&id=1c6c70e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c6c70e5",
  null
  
)

export default component.exports