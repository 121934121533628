

























import Vue from 'vue'
export default Vue.extend({
  name: 'AnchorMenu',
  props: {
    links: {
      required: true
    }
  },
  computed: {
    isMobile: function () {
      if (window.innerWidth < 992) {
        return true
      }
      return false
    }
  },
  methods: {
    scrollTo (link) {
      const yOffset = this.isMobile ? -60 : -80;
      const element = document.querySelector(link);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }
})
