









import Vue from 'vue'
import QuoteCarousel from '@/components/QuoteCarousel/QuoteCarousel.vue'

export default Vue.extend({
  components: { QuoteCarousel },
  name: 'SectionQuotes'
})
