


















































import Vue from 'vue'

export default Vue.extend({
  name: 'MobileMenuLinks',

  data () {
    return {
      group: null
    }
  }
})
