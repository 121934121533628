<template>
  <div
      class="portfolio-details"
      :class="classMode"
  >
    <div class="portfolio-details__content">
      <h3>{{ itemData.content.title }}</h3>
      <p>{{ itemData.content.text }}</p>
    </div>
    <div class="portfolio-details__techs">
      <div class="portfolio-details__techs_item">
        <p>Hours</p>
        <h5>{{ itemData.content.hours }} h</h5>
      </div>
      <div class="portfolio-details__techs_item">
        <p>Technology</p>
        <div class="portfolio-details__techs_icons">
          <div
            v-for="item in itemData.content.technologiesMobile"
            :key="item.id"
          >
            <img
              :src="item.src"
              :alt="item.alt"
              width="24px"
              style="max-height: 24px"
            />
          </div>
        </div>
      </div>
      <div class="portfolio-details__techs_item">
        <p>Platforms</p>
        <div class="portfolio-details__techs_icons">
          <div
            v-for="item in itemData.content.platformsMobile"
            :key="item.id"
          >
            <img
              :src="item.src"
              :alt="item.alt"
              width="24px"
              height="24px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="portfolio-details__author">
      <div class="portfolio-details__author_name">{{ itemData.content.author.name }}</div>
      <div class="portfolio-details__author_position">{{ itemData.content.author.position }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioMobileDetails",
  props: {
    itemData: { required: true },
    classMode: { default: 'portfolio-item-details' }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;

  &__content {
    h3 {
      margin-bottom: 25px;
      line-height: 1;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__techs {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  &__techs_item {
    p {
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
    }
    h4 {
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
    }
  }
  &__techs_icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      flex-basis: 50%;
      text-align: center;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  &__author_name {
    font-size: 18px;
    font-weight: bold;
  }
  &__author_position {
    font-size: 14px;
  }

  @media (min-width: $desk-small) {
    display: none;
  }
}
</style>

<style lang="scss">
@media (max-width: $mobile-medium) {
  .carousel-3d-slide.current {
    .portfolio-mobile-details {
      top: 15px !important;
      bottom: 14px !important;
      left: 17px !important;
      right: 17px !important;
      border-radius: 25px !important;
    }
  }
}
@media (max-width: $max-desk-small) {
  .carousel-3d-slide.current {
    .portfolio-item-details {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(63.82deg, #000000de 0%, #54329cc2 91.34%);

      padding: 140px 50px;
      border-radius: 0;

      & * {
        color: #fff;
      }
    }
    .portfolio-mobile-details {
      position: absolute;
      top: 19px;
      left: 19px;
      right: 19px;
      bottom: 19px;
      background: linear-gradient(63.82deg, #000000de 0%, #54329cc2 91.34%);

      padding: 50px 24px;
      border-radius: 33px;

      & * {
        color: #fff;
      }
    }
  }
}

@media (max-width: $mobile-large) {
  .carousel-3d-slide.current {
    .portfolio-item-details {
      padding: 70px 35px;
    }
  }
}
@media ($mobile-medium) {
  .carousel-3d-slide.current {
    .portfolio-item-details {
      padding: 30px 30px;
    }
  }
}
</style>