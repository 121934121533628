



























import Vue from 'vue'
import MobileMenuContacts from './MobileMenuContacts.vue'
import MobileMenuLinks from './MobileMenuLinks.vue'
import { mapGetters } from "vuex"

export default Vue.extend({
  components: { MobileMenuLinks, MobileMenuContacts },
  name: 'MobileMenu',

  data () {
    return {
      drawer: true
    }
  },
  computed: {
    ...mapGetters({
      'mobileMenu': 'UI/mobileMenu'
    }),
    dialog: {
      get () {
        return this.mobileMenu
      },
      set (val) {
        this.$store.dispatch('UI/mobileMenu', val)
      }
    }
  },
  methods: {
    closeMobile () {
      this.$store.dispatch('UI/mobileMenu', false)
    }
  }
})
