import Vue from 'vue'
import Vuex from 'vuex'
import UI from './UI'
import portfolioItems from "@/store/datas/portfolio-items"
import portfolioMobileApps from "@/store/datas/portfolio-mobile-apps"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    banner: localStorage.getItem('banner-ua') || '1',
    portfolioWebVersion: true,
    selectedCat: 'all',
    portfolioItems: portfolioItems,
    portfolioMobileApps: portfolioMobileApps
  },

  // mutations
  mutations: {
    PORTFOLIO_WEB_VERSION (state, value) {
      state.portfolioWebVersion = value
    },
    SELECT_CAT (state, value) {
      state.selectedCat = value
    },
    BANNER (state, value) {
      state.banner = value
      localStorage.setItem('banner-ua', value)
    }
  },

  // actions
  actions: {
    switchPortfolioWebVersion ({ commit }, value) {
      commit('PORTFOLIO_WEB_VERSION', value)
    },
    selectCategory ({ commit }, value) {
      commit('SELECT_CAT', value)
    },
    banner ({ commit }, value) {
      commit('BANNER', value)
    },
  },

  // getters
  getters: {
    banner: state => state.banner === '1',
    portfolioWebVersion: state => state.portfolioWebVersion,
    portfolioItems: state => {
      if (state.selectedCat === 'all') {
        return state.portfolioItems
      }

      return state.portfolioItems.filter(item => {
        return item.category === state.selectedCat
      })
    },
    portfolioMobileApps: state => {
      if (state.selectedCat === 'all') {
        return state.portfolioMobileApps
      }

      return state.portfolioMobileApps.filter(item => {
        return item.category === state.selectedCat
      })
    }
  },
  modules: {
    UI
  },
})
