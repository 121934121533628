








































import Vue from 'vue'
export default Vue.extend({
  name: 'SectionMobileApp',
  data () {
    return {
      items: [
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/idea.svg'),
            alt: 'idea'
          },
          title: 'Brainstorm',
          text: 'Discussing your ideas and determining app requirements.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/graphic.svg'),
            alt: 'app design'
          },
          title: 'Design',
          text: 'Developing the UI/UX design for the app.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/newspaper.svg'),
            alt: 'prototype'
          },
          title: 'Prototype',
          text: 'Creating an app prototype and finalizing the details.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/graphic-mobilesvg.svg'),
            alt: 'App development'
          },
          title: 'Development',
          text: 'Working on the front-end and back-end stages.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/mobile-list.svg'),
            alt: 'Mobile app testing'
          },
          title: 'QA',
          text: 'Testing the app for usability, performance, and security.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/rocket.svg'),
            alt: 'App deployment'
          },
          title: 'Deployment',
          text: 'Getting the app ready for the market launch.'
        },
        {
          icon: {
            src: require('@/assets/icons/development/mobile-develop/support.svg'),
            alt: 'Post-deployment support'
          },
          title: 'Maintenance',
          text: 'Polishing the app to ensure smooth performance.'
        },
      ]
    }
  }
})
