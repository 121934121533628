import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#2B3B54',
        'light-green': '#A5DF52',
        'link-blue': '#46B8F9'
      }
    }
  }
});
