






















































import Vue from 'vue'

export default Vue.extend({
  name: 'CallToActions',
  props: {
    mode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    link: {
      type: String
    },
  }
})
