


























import Vue from 'vue'
import FooterSocials from "@/components/particles/Footer/FooterSocials.vue";

export default Vue.extend({
  name: 'FooterCopyright',
  components: {FooterSocials},
  computed: {
    currentYear: function () {
      return new Date().getFullYear()
    }
  }
})
