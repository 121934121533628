import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// @ts-ignore
import router from './router/index.js'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from "vue-meta"
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueReCaptcha, { siteKey: '6LcNaWceAAAAANUxVKsrdSb3TkbdHcQ-K1Cqt13u' })

new Vue({
  // @ts-ignore
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
