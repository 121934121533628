import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"footer-links pt-0",attrs:{"color":"transparent","dark":"","dense":""}},[_c(VSubheader,{staticClass:"pl-4 footer-links__title"},[_vm._v(_vm._s(_vm.title))]),_c(VListItemGroup,{attrs:{"dark":""}},_vm._l((_vm.navLinks),function(item){return _c(VListItem,{key:item.id,attrs:{"link":"","to":item.link}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"footer-links-item__title"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }