import { render, staticRenderFns } from "./SectionQuotes.vue?vue&type=template&id=b6c45300&scoped=true&"
import script from "./SectionQuotes.vue?vue&type=script&lang=ts&"
export * from "./SectionQuotes.vue?vue&type=script&lang=ts&"
import style0 from "./SectionQuotes.vue?vue&type=style&index=0&id=b6c45300&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c45300",
  null
  
)

export default component.exports