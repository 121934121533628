



















import Vue from 'vue'
import { Carousel3d } from 'vue-carousel-3d'

export default Vue.extend({
  name: 'MainCarousel',
  components: {
    Carousel3d
  },

  props: {
    sizes: {
      required: true,
      type: Object
    },
    configs: {
      required: false,
      default: () => {
        return {
          autoplay: true,
          autoplayTime: 3000,
          loop: true,
          display: 5,
        }
      }
    }
  },

  computed: {
    slideWidth: function () {
      if (window.innerWidth <= 1440 && window.innerWidth >= 1200) {
        return this.sizes.medium.width
      } else if (window.innerWidth < 1200) {
        return this.sizes.small.width
      }
      return this.sizes.large.width
    },
    slideHeight: function () {
      if (window.innerWidth <= 1440 && window.innerWidth >= 1200) {
        return this.sizes.medium.height
      } else if (window.innerWidth < 1200) {
        return this.sizes.small.height
      }
      return this.sizes.large.height
    },
    slideSpacing: function () {
      if (window.innerWidth <= 1440 && window.innerWidth >= 1200) {
        return this.sizes.medium.spacing
      } else if (window.innerWidth < 1200) {
        return this.sizes.small.spacing
      }
      return this.sizes.large.spacing
    }
  },
})
