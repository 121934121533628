export default [
  {
    name: 'backend',
    isActive: true,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/python.svg'), alt:"python" },
      { id: 2, name: require('../../../../assets/icons/development/techs/php.svg'), alt:"php" },
      { id: 3, name: require('../../../../assets/icons/development/techs/go.svg'), alt:"go" },
      { id: 4, name: require('../../../../assets/icons/development/techs/nodejs.svg'), alt:"node" },
      { id: 5, name: require('../../../../assets/icons/development/techs/django.svg'), alt:"django" },
      { id: 6, name: require('../../../../assets/icons/development/techs/laravel.svg'), alt:"laravel" },
      { id: 7, name: require('../../../../assets/icons/development/techs/gin.svg'), alt:"gin" },
      { id: 8, name: require('../../../../assets/icons/development/techs/express.svg'), alt:"express" },
      { id: 9, name: require('../../../../assets/icons/development/techs/flask.svg'), alt:"flask" },
      { id: 10, name: require('../../../../assets/icons/development/techs/guzzle.svg'), alt:"guzzle" },
      { id: 11, name: require('../../../../assets/icons/development/techs/beego.svg'), alt:"beego" },
      { id: 12, name: require('../../../../assets/icons/development/techs/sails.svg'), alt:"sails" },
    ]
  },
  {
    name: 'frontend',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/vue.svg'), alt:"vue" },
      { id: 2, name: require('../../../../assets/icons/development/techs/react.svg'), alt:"react" },
      { id: 3, name: require('../../../../assets/icons/development/techs/php.svg'), alt:"php" },
      { id: 4, name: require('../../../../assets/icons/development/techs/react-native.svg'), alt:"react native" },
      { id: 5, name: require('../../../../assets/icons/development/techs/nuxt.svg'), alt:"nuxt" },
      { id: 6, name: require('../../../../assets/icons/development/techs/next.svg'), alt:"next" },
      { id: 7, name: require('../../../../assets/icons/development/techs/laravel.svg'), alt:"laravel" },
      { id: 8, name: require('../../../../assets/icons/development/techs/yii.svg'), alt:"yii" },
      { id: 9, name: require('../../../../assets/icons/development/techs/magento.svg'), alt:"magento" },
      { id: 10, name: require('../../../../assets/icons/development/techs/wordpress.svg'), alt:"wordpress" }
    ]
  },
  {
    name: 'database',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/mongo.svg'), alt:"mongo db" },
      { id: 2, name: require('../../../../assets/icons/development/techs/postgre.svg'), alt:"postgre" },
      { id: 3, name: require('../../../../assets/icons/development/techs/mysql.svg'), alt:"mysql" },
    ]
  },
  {
    name: 'devops',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/docker.svg'), alt:"docker" },
      { id: 2, name: require('../../../../assets/icons/development/techs/ansible.svg'), alt:"ansible" },
      { id: 3, name: require('../../../../assets/icons/development/techs/google-cloud.svg'), alt:"google-cloud" },
      { id: 4, name: require('../../../../assets/icons/development/techs/aws.svg'), alt:"aws" },
      { id: 5, name: require('../../../../assets/icons/development/techs/predict.svg'), alt:"frame" },
      { id: 6, name: require('../../../../assets/icons/development/techs/kubernetes.svg'), alt:"kubernetes" },
      { id: 7, name: require('../../../../assets/icons/development/techs/architecture.svg'), alt:"architecture" },
    ]
  },
  {
    name: 'api',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/google.svg'), alt:"google" },
      { id: 2, name: require('../../../../assets/icons/development/techs/facebook.svg'), alt:"facebook" },
      { id: 3, name: require('../../../../assets/icons/development/techs/twitter.svg'), alt:"twitter" },
      { id: 4, name: require('../../../../assets/icons/development/techs/linkedin.svg'), alt:"linkedin" },
      { id: 5, name: require('../../../../assets/icons/development/techs/discord.svg'), alt:"discord" },
      { id: 6, name: require('../../../../assets/icons/development/techs/paypal.svg'), alt:"paypal" },
      { id: 7, name: require('../../../../assets/icons/development/techs/coinpayments.svg'), alt:"coinpayments" },
      { id: 8, name: require('../../../../assets/icons/development/techs/multicards.svg'), alt:"multicards" },
      { id: 9, name: require('../../../../assets/icons/development/techs/ssp.svg'), alt:"ssp" },
      { id: 10, name: require('../../../../assets/icons/development/techs/coingate.svg'), alt:"coingate" },
    ]
  },
  {
    name: 'tech',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/memcached.svg'), alt:"memcached" },
      { id: 2, name: require('../../../../assets/icons/development/techs/server-side.svg'), alt:"server-side" },
      { id: 3, name: require('../../../../assets/icons/development/techs/redis.svg'), alt:"redis" },
      { id: 4, name: require('../../../../assets/icons/development/techs/websocket.svg'), alt:"websocket" },
      { id: 5, name: require('../../../../assets/icons/development/techs/restapi.svg'), alt:"restapi" },
      { id: 6, name: require('../../../../assets/icons/development/techs/celery.svg'), alt:"celery" },
      { id: 7, name: require('../../../../assets/icons/development/techs/xml.svg'), alt:"xml rpc" },
      { id: 8, name: require('../../../../assets/icons/development/techs/rabbit.svg'), alt:"rabbit" },
      { id: 9, name: require('../../../../assets/icons/development/techs/long.svg'), alt:"long pollit" },
      { id: 10, name: require('../../../../assets/icons/development/techs/webrtc.svg'), alt:"webrtc" },
      { id: 11, name: require('../../../../assets/icons/development/techs/github.svg'), alt:"github" },
      { id: 12, name: require('../../../../assets/icons/development/techs/gitlab.svg'), alt:"gitlab" },
    ]
  },
  {
    name: 'bot',
    isActive: false,
    list: [
      { id: 1, name: require('../../../../assets/icons/development/techs/personal.svg'), alt:"personal" },
      { id: 2, name: require('../../../../assets/icons/development/techs/telegram.svg'), alt:"telegram" },
      { id: 3, name: require('../../../../assets/icons/development/techs/discord.svg'), alt:"discord" },
      { id: 4, name: require('../../../../assets/icons/development/techs/viber.svg'), alt:"viber" },
      { id: 5, name: require('../../../../assets/icons/development/techs/watchap.svg'), alt:"watchap" },
    ]
  }
]