








































import Vue from 'vue'
import SectionCountItem from "@/views/Development/Sections/Count/SectionCountItem.vue"

export default Vue.extend({
    name: 'SectionCount',
    components: {
        SectionCountItem
    }
})
