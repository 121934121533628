



























































import Vue from 'vue'

export default Vue.extend({
    name: 'SectionDevelopment',
    props: {
        image: {required: true, type: Object},
        title: {required: true, type: String},
        subtitle: {required: true, type: String},
        firstBlock: {required: true, type: Array},
        secondBlock: {required: true, type: Array},
        imageIsBig: {default: false}
    }
})
