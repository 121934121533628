export default {
   namespaced: true,
   state () {
      return {
         mobileMenu: false
      }
   },
   mutations: {
      MOBILE_MENU (state, val) {
         state.mobileMenu = val
      }
   },
   actions: {
      mobileMenu ({ commit }, val) {
         commit('MOBILE_MENU', val)
      }
   },
   getters: {
      mobileMenu: state => state.mobileMenu
   }
}