










































import Vue from 'vue'
export default Vue.extend({
  name: 'QuoteCarousel',

  data () {
    return {
      slides: [
        // {
        //   id: 1,
        //   img: require('@/assets/img/development/oleh.png'),
        //   name: 'Oleh Dankevych',
        //   position: 'CEO, ARI.company',
        //   quote: 'As the CEO of ARI.company, I always ask our customers for feedback to ensure that my team continues to provide an outstanding customer experience. Our current and former clients constantly praise us for our technical competence, attention to detail, strict adherence to the project timeline, and precision in all aspects of the project. You can be certain that when you choose our company, you choose excellence and experience.'
        // },
        {
          id: 2,
          img: require('@/assets/img/development/margaret.webp'),
          name: 'Margaret Mora',
          position: 'CMO, NDA',
          quote: 'I was happy to work with ARI.company while developing a customized website for my business. They were flexible, forward-thinking, and left a very positive impression of their company.'
        },
        {
          id: 3,
          img: require('@/assets/img/development/antony.webp'),
          name: 'Anthony Jackson',
          position: 'CTO, NDA',
          quote: 'They developed the best mobile app for my travel agency I could ever ask for. Now managing bookings and keeping customers engaged is much easier. More than that, I was impressed by their technical expertise and ability to understand my demand easily.'
        },
        {
          id: 4,
          img: require('@/assets/img/development/jennifer.webp'),
          name: 'Jennifer Williams',
          position: 'CEO, NDA',
          quote: 'I was lucky to work with ARI.company while developing a custom Bot for car rental. This project helped me save hours of administrative work and get rid of piles of paperwork. Apart from technical expertise, ARI.company demonstrated high professionalism and problem-solving.'
        },
      ]
    }
  },

  mounted () {
    this.sliderProc();
  },

  methods: {
    sliderProc () {
      let sliderImagesBox = document.querySelectorAll('.cards-box');
      sliderImagesBox.forEach(el => {
        let imageNodes = el.querySelectorAll('.card:not(.hide)')
        let arrIndexes: any = [];
        (() => {
          let start = 0;
          while (imageNodes.length > start) {
            arrIndexes.push(start++);
          }
        })();

        let setIndex = (arr: Array<Number>) => {
          for(let i = 0; i < imageNodes.length; i++) {
            imageNodes[i].setAttribute("data-slide", arr[i].toString())
          }
        }
        el.addEventListener('click', () => {
          arrIndexes.unshift(arrIndexes.pop());
          setIndex(arrIndexes)
        })
        setIndex(arrIndexes)
      });
    }
  }
})
