














































import Vue from 'vue'

export default Vue.extend({
    name: 'SectionHero',
    data() {
        return {
            slideCount: 1,
            slideImages: [
                {id: 1, src: require('@/assets/img/development/Mockup1.webp'), alt: 'cryptocurrency'},
                {id: 2, src: require('@/assets/img/development/Mockup2.webp'), alt: 'casino'},
                {id: 3, src: require('@/assets/img/development/Mockup3.webp'), alt: 'travel'},
                {id: 4, src: require('@/assets/img/development/Mockup5.webp'), alt: 'chatbot'},
            ]
        }
    },

    computed: {
        generateSlide: function (): object {
            return this.slideImages.filter(item => {
                if (item.id === this.slideCount) {
                    return item;
                }
            })[0];
        }
    },

    created() {
        setInterval(() => {
            if (this.slideCount === this.slideImages.length) {
                this.slideCount = 1;
            } else {
                this.slideCount++;
            }
        }, 2000);
    }
});
