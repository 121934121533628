export default [
    {
        id: 1, src: require('@/assets/img/development/portfolio/iPhone-currency.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'payment-system',
        content: {
            title: 'Online payment app',
            text: 'It\'s a mobile application that allows users to send and receive money online.',
            hours: 310,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/react-dark.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/node-dark.svg'), alt: 'node.js'},
                {id: 3, src: require('@/assets/icons/development/mongo-dark.svg'), alt: 'mongo'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/react-logo.svg'), alt: 'react'},
                {id: 2, src: require('@/assets/icons/development/techs/nodejs.svg'), alt: 'node'},
                {id: 3, src: require('@/assets/icons/development/techs/mongo-logo.svg'), alt: 'mongo'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'Product Owner',
                name: 'Ryan B.'
            }
        }
    },
    {
        id: 2, src: require('@/assets/img/development/portfolio/iPhone-auto.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'ecommerce',
        content: {
            title: 'Car buy & sell app',
            text: 'It\'s a mobile application that allows users to sell or buy a car in Europe.',
            hours: 256,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/react-dark.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/python-dark.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/mongo-dark.svg'), alt: 'mongo'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/react-logo.svg'), alt: 'react'},
                {id: 2, src: require('@/assets/icons/development/techs/python-logo.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/techs/mongo-logo.svg'), alt: 'mongo'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'Founder',
                name: 'Charlie K'
            }
        }
    },
    {
        id: 3, src: require('@/assets/img/development/portfolio/iPhone-crypto.webp'),
        alt: '', isActive: true, isNext: false,
        category: 'crypto',
        content: {
            title: 'Spot cryptocurrency exchange app',
            text: 'It\'s a mobile application that allows people to find opportunities for cryptocurrency exchange.',
            hours: 420,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/vue-dark.svg'), alt: 'Vue'},
                {id: 2, src: require('@/assets/icons/development/python-dark.svg'), alt: 'Python'},
                {id: 3, src: require('@/assets/icons/development/postgre-dark.svg'), alt: 'Postgre'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/vue.svg'), alt: 'vue.js'},
                {id: 2, src: require('@/assets/icons/development/techs/python-logo.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/techs/postgre-logo.svg'), alt: 'Postgre'}
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'NDA',
                name: 'Robert A.'
            }
        }
    },
    {
        id: 4, src: require('@/assets/img/development/portfolio/iPhone-jobs.webp'),
        alt: '', isActive: false, isNext: true,
        category: 'job search',
        content: {
            title: 'Job search app',
            text: 'It\'s a mobile application that allows people to find jobs online.',
            hours: 255,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/vue-dark.svg'), alt: 'Vue'},
                {id: 2, src: require('@/assets/icons/development/node-dark.svg'), alt: 'Node'},
                {id: 3, src: require('@/assets/icons/development/mysql-dark.svg'), alt: 'Mysql'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/vue.svg'), alt: 'vue.js'},
                {id: 2, src: require('@/assets/icons/development/techs/nodejs.svg'), alt: 'Node'},
                {id: 3, src: require('@/assets/icons/development/techs/mysql-logo.svg'), alt: 'Mysql'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'Product Owner',
                name: 'Madelyn S.'
            }
        }
    },
    {
        id: 5, src: require('@/assets/img/development/portfolio/iPhone-hotel-bot.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'chatbot',
        content: {
            title: 'Hotel reservation bot',
            text: 'It\'s a bot that allows people to find accommodation and book it.',
            hours: 130,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/python-dark.svg'), alt: 'python'},
                {id: 2, src: require('@/assets/icons/development/mongo-dark.svg'), alt: 'Mongo'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/python-logo.svg'), alt: 'python'},
                {id: 2, src: require('@/assets/icons/development/techs/mongo-logo.svg'), alt: 'mongo'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'Founder',
                name: 'Igor S.'
            }
        }
    },
    {
        id: 6, src: require('@/assets/img/development/portfolio/iPhone-cars-bot.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'chatbot',
        content: {
            title: 'Car rental bot',
            text: 'It is a bot that allows people to register for a car test drive and make a deal if they like it.',
            hours: 270,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/node-dark.svg'), alt: 'node'},
                {id: 2, src: require('@/assets/icons/development/mysql-dark.svg'), alt: 'mysql'}
            ],
            platforms: [
                {id: 1, src: require('@/assets/icons/development/monitor.svg'), alt: 'desk'},
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/nodejs.svg'), alt: 'node'},
                {id: 2, src: require('@/assets/icons/development/techs/mysql-logo.svg'), alt: 'mysql'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/architecture.svg'), alt: 'Desktop'},
                {id: 2, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'Founder',
                name: 'Igor S.'
            }
        }
    },
    {
        id: 7, src: require('@/assets/img/development/portfolio/iPhone-travel.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'travel',
        content: {
            title: 'Travel app',
            text: 'It\'s a mobile application that analyses a desire to travel and lists the best vacation spots. Also, there\'s an option for booking tours.',
            hours: 330,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/react-dark.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/python-dark.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/mysql-dark.svg'), alt: 'mysql'}

            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/react-mobile.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/techs/python-logo.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/techs/mysql-logo.svg'), alt: 'mysql'},
            ],
            platformsMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/ios.svg'), alt: 'IOS'},
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'CEO',
                name: 'John G.'
            }
        }
    },
    {
        id: 8, src: require('@/assets/img/development/portfolio/iPhone-casino.webp'),
        alt: '', isActive: false, isNext: false,
        category: 'gambling',
        content: {
            title: 'Private online casino',
            text: 'It\'s a mobile application for a private online casino with different games inside.',
            hours: 460,
            technologies: [
                {id: 1, src: require('@/assets/icons/development/react-dark.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/python-dark.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/postgre-dark.svg'), alt: 'postgre'},
            ],
            platforms: [
                {id: 2, src: require('@/assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
                {id: 3, src: require('@/assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
            ],
            technologiesMobile: [
                {id: 1, src: require('@/assets/icons/development/techs/react-mobile.svg'), alt: 'react.js'},
                {id: 2, src: require('@/assets/icons/development/techs/python-logo.svg'), alt: 'python'},
                {id: 3, src: require('@/assets/icons/development/techs/postgre-logo.svg'), alt: 'postgre'},
            ],
            platformsMobile: [
                {id: 2, src: require('@/assets/icons/development/techs/android.svg'), alt: 'Android'},
            ],
            author: {
                position: 'NDA',
                name: 'James S.'
            }
        }
    }
];
