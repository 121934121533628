






















import Vue from 'vue'
export default Vue.extend({
  name: 'CompanyLinks',
  props: {
    title: {
      required: true,
      type: String
    },
    navLinks: {
      required: true
    }
  }
})
