
































































import Vue from 'vue'
import PortfolioMobileDetails from "@/components/particles/Portfolio/PortfolioMobileDetails.vue"

export default Vue.extend({
  name: 'PortfolioCarouselItem',
  components: {
    PortfolioMobileDetails
  },
  props: {
    itemData: {
      required: true
    }
  },
  data () {
    return {
      detailsActivated: false
    }
  },
  methods: {
    showDetails () {
      this.detailsActivated = !this.detailsActivated
    }
  }
})
