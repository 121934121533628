




























































import Vue from 'vue'

export default Vue.extend({
  name: 'SectionFaq',
  props: {
    items: { required: true, type: Array }
  },
  data () {
    return {
      showFull: false
    }
  },
  computed: {
    startItems: function (): any {
      if (!this.showFull) {
        return this.items.slice(0, 3)
      }
      return this.items
    },
  }
})
