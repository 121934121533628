<template>
    <v-container fluid class="contact">
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="validateAndSubmit"
        >
            <v-row class="contact__wrapper container-wrapper">
                <v-col
                    v-if="!isSended"
                    cols="12"
                    lg="7"
                    class="contact__forms px-0 px-sm-3"
                >
                    <v-row class="contact__title pb-5">
                        <v-col>
                            <h2>Tell us about your project!</h2>
                        </v-col>
                    </v-row>
                    <v-row class="contact__inputs">
                        <v-col class="py-0" cols="12">
                            <div class="mb-1">Full name*</div>
                            <v-text-field
                                v-model="name"
                                class="ari-input contact__inputs_item"
                                outlined
                                clearable
                                :rules="[rules.required, rules.maxSymbols]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="mb-1">Email*</div>
                            <v-text-field
                                v-model="email"
                                :rules="[rules.required, rules.maxSymbols, rules.email]"
                                class="ari-input contact__inputs_item"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="mb-1">Phone</div>
                            <vue-tel-input v-model="phone"></vue-tel-input>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="mb-1">How did you hear about us?*</div>
                            <v-select
                                v-model="whereHear"
                                :items="platformItems"
                                class="ari-input contact__inputs_item"
                                :rules="[rules.required]"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="mb-1">What is your budget?*</div>
                            <v-select
                                v-model="budget"
                                :items="budgetItems"
                                class="ari-input contact__inputs_item"
                                :rules="[rules.required]"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col class="pb-7 pb-sm-0 pt-0" cols="12" sm="6">
                            <div class="mb-1">Project information*</div>
                            <v-textarea
                                v-model="projectInfo"
                                class="ari-input contact__inputs_item"
                                outlined
                                name="input-7-4"
                                :rules="[rules.required, rules.minSymbolsProject, rules.maxSymbolsProject]"
                            ></v-textarea>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <div class="mb-1">Attachment (optional)</div>
                            <div class="custom-file-input">
                                <label
                                    for="upload-photo"
                                    class="custom-file-input__label"
                                >
                                    <span
                                        v-if="!uploadFile"
                                        class="custom-file-input__label_upload"
                                    >
                                        <img src="@/assets/icons/upload.svg" alt="Upload">
                                        <span>Drag and drop here</span>
                                    </span>
                                    <span v-else>
                                        {{ uploadFile.name }}
                                    </span>
                                </label>
                                <input
                                    id="upload-photo"
                                    class="custom-file-input__button"
                                    type="file"
                                    accept="application/pdf, .doc, .docx"
                                    @change="handleFileChange"
                                >
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="contact__terms">
                        <v-col class="d-flex flex-nowrap align-center pt-0">
                            <v-checkbox
                                color="#7A59D6"
                                v-model="agree"
                                @click="recaptcha"
                            ></v-checkbox>
                            <div>I agree to the <a href="/privacy-policy" target='_blank'>ARI.company’s Privacy</a> & <a
                                href="/cookie-policy" target="_blank">Cookie Policy</a></div>
                        </v-col>
                    </v-row>
                    <v-row class="contact__submit">
                        <v-col class="pt-0">
                            <button
                                class="px-8 green-btn"
                                style="text-transform: initial"
                                :disabled="!valid"
                                data-badge="inline"
                            >
                                Submit
                            </button>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="isSended" cols="12" lg="7">
                    <div class="text-center" style="padding-top: 64px">
                        <img src="@/assets/icons/success-green-check-mark-icon.svg" alt="success" width="120" height="120">
                        <h2 class="mt-4">Your application has been sent!</h2>
                        <h4>We will contact you shortly. Thanks for the application!</h4>
                    </div>
                </v-col>
                <v-col cols="12" lg="5" class="contact__about d-flex align-center">
                    <div>
                        <h3 class="primary-text mb-3">What’s next?</h3>
                        <p>We'll get back to you within 1 business day to discuss your project and answer all your
                            questions.</p>
                        <p>While you're waiting for the reply, you can review our portfolio and see what our team can
                            do.</p>
                        <p>If necessary, we can sign a non-disclosure agreement before you reveal your project details
                            to us.</p>
                        <p>Our extensive technical expertise will help us bring all your ideas to life and deliver a
                            solution that perfectly fits your needs.</p>
                    </div>
                </v-col>
            </v-row>
        </v-form>

        <v-snackbar
            v-model="fileSnackbar"
            :timeout="fileTimeout"
            color="error"
        >
            The file size should not exceed 1 MB.

            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="fileSnackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
    name: 'Contact',
    components: {
        VueTelInput
    },
    data() {
        return {
            fileSnackbar: false,
            fileTimeout: 2000,

            valid: false,
            agree: false,
            name: '',
            email: '',
            phone: '',
            budget: '',
            whereHear: '',
            projectInfo: '',
            uploadFile: null,
            rcaptchaHash: '',
            isSended: false,

            budgetItems: [
                '£500',
                '£1000',
                '£3000',
                '£5000',
                '£10000',
                '£50000+'
            ],
            platformItems: [
                'Linkedin',
                'Freelancehunt',
                'Upwork',
                'Goodfirm',
                'Clutch',
                'Google'
            ],

            rules: {
                required: value => !!value || 'This is required.',
                counter: value => value.length <= 20 || 'Max 20 characters',
                maxSymbols: value => value.length <= 100 || 'Max 100 characters',
                minSymbolsProject: value => value.length >= 150 || 'Min 150 characters',
                maxSymbolsProject: value => value.length <= 2000 || 'Max 2000 characters',
                maxFileSize: value => value.length <= 2000 || 'Max file size 2MB',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Please enter a valid emails address.'
                },
            },
        }
    },

    methods: {
        handleFileChange(e) {
            let file = e.target.files[0]
            if (file && file.size > 1000000) {
                this.fileSnackbar = true
                document.getElementById('upload-photo').value = ''
            } else if (file) {
                this.uploadFile = e.target.files[0]
            }
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.rcaptchaHash = await this.$recaptcha('login')
        },
        validateAndSubmit() {
            if (this.valid) {
                const formData = new FormData()
                formData.append('fullname', this.name)
                formData.append('email', this.email)
                formData.append('phone', this.phone)
                formData.append('hearabout', this.whereHear)
                formData.append('budget', this.budget)
                formData.append('pinfo', this.projectInfo)
                formData.append('file', this.uploadFile)
                formData.append('rcaptcha', this.rcaptchaHash)

                this.postData(formData)
                    .then(data => {
                        if (data.status === "OK") {
                            this.isSended = true
                        }
                    })
            }
        },
        async postData(formData) {
            const response = await fetch('https://api.ari.company/api/form/doit', {
                method: 'POST',
                body: formData
            })
            return await response.json()
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" scoped>
.contact {
    padding-top: 64px;
    padding-bottom: 94px;

    &__title {
        h2 {
            color: #0085FF;
        }
    }

    &__inputs_item {
        border-radius: 10px !important;
    }

    &__about {
        padding: 0;
        padding-top: 64px;
    }

    &__terms {
        a, a:focus, a:active, a:visited {
            color: $active-link;
            text-decoration: none;
        }
    }

    .custom-file-input {
        height: 150px;

        &__label_upload span {
            width: 100%;
            margin-top: 5px;
        }
    }
}

@media (min-width: $pad-medium) {
    .contact {
        &__about {
            padding: 64px;
        }
    }
}
</style>
