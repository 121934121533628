




















import Vue from 'vue'
import { mapGetters } from "vuex"
import { Slide } from 'vue-carousel-3d'
import MainCarousel from '@/components/MainCarousel/MainCarousel.vue'
import PortfolioCarouselItem from './PortfolioCarouselItem.vue'

export default Vue.extend({
  name: 'PortfolioCarousel',

  components: {
    MainCarousel,
    PortfolioCarouselItem,
    Slide
  },

  props: {
    items: { required: true },
    carouselOptions: { required: false }
  },

  data () {
    return {
      screenSizes: {
        small: {
          width: 448,
          height: 700,
          spacing: 400
        },
        medium: {
          width: 950,
          height: 640,
          spacing: 450
        },
        large: {
          width: 1100,
          height: 700,
          spacing: 630
        }
      },
    }
  }
})
