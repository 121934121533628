export default [
    {
        id: 1,
        title: "Can you help me even if I have a project vision but don't have technical details?",
        text: "Absolutely! It's the way we usually work. Most of our clients have great project ideas but don't have the technical skills to bring them to life. By combining your vision with our technical expertise, we can create a revolutionary product that will take your business to the next level."
    },
    {
        id: 2,
        title: "How much does it cost to develop an app?",
        text: "The price for app development depends on many factors. Let us know the details, and we'll let you know the final price for your project."
    },
    {
        id: 3,
        title: "How long does it take to develop an app or software product?",
        text: "The amount of time needed to create a mobile app, web project, or software product depends on its complexity, requirements, and goals. If you provide us with a clear product specification and requirements, we can ensure that we'll be able to bring it to life as soon as possible."
    },
    {
        id: 4,
        title: "Can I decide on app design?",
        text: "Sure! The goal of our team is to bring your vision to life and ensure that you're satisfied with the product we develop. At the beginning of every project, our designers create a final product prototype and get the client's approval. If needed, we can also make adjustments in product design during the development stages."
    },
    {
        id: 5,
        title: "Do you work under contracts and non-disclosure agreements?",
        text: "Yes, before getting started with a project, we usually sign a non-disclosure agreement and a contract outlining project specifications, cost, timeline, deliverables, payment, and other details."
    },
    {
        id: 6,
        title: "Do you offer post-deployment support?",
        text: "Yes, we offer various post-deployment support packages for products developed by us and for software and apps created by other companies."
    },
    {
        id: 7,
        title: "Can you make updates to an app developed by another company?",
        text: "Yes! But please keep in mind that it will take some time to analyze the product code before making any modifications."
    },
    {
        id: 8,
        title: "Do you work with international clients?",
        text: "Yes, over the past 10 years, we've completed over 150 projects for clients from different countries all over the world."
    },
    {
        id: 9,
        title: "Do you offer web development services?",
        text: "Yes, our team can create websites, advanced web applications, web portals, etc."
    },
    {
        id: 10,
        title: "Will I receive regular project updates?",
        text: "Yes! Usually, we provide all our customers with bi-weekly updates about their projects, regardless of the project duration or scale. Thus, if necessary, you can always contact us with all your questions or concerns about the project."
    },
]