



















































































import Vue from 'vue'
import techslist from './tech-list'

export default Vue.extend({
    name: 'SectionTech',
    data() {
        return {
            techs: techslist,
            buttons: [
                {id: 1, value: 'backend', title: 'Back End'},
                {id: 2, value: 'frontend', title: 'Front End'},
                {id: 3, value: 'database', title: 'Database'},
                {id: 4, value: 'devops', title: 'DevOps'},
                {id: 5, value: 'api', title: 'API'},
                {id: 6, value: 'tech', title: 'Tech'},
                {id: 7, value: 'bot', title: 'Bot'},
            ]
        }
    },
    methods: {
        changeTech(techName: string): void {
            this.techs = this.techs.map((item: any) => {
                if (techName === item.name) {
                    item.isActive = true
                    return item
                }
                item.isActive = false
                return item;
            })
        }
    }
})
