<template>
  <div class="footer__social">
    <a
        href="https://www.facebook.com/ari.company/"
        class="social-item"
        target="_blank"
    >
      <img
          src="@/assets/icons/development/facebook-light.svg"
          alt="facebook"
      >
    </a>
    <a
        href="https://www.linkedin.com/company/ari-company/"
        class="social-item"
        target="_blank"
    >
      <img
          src="@/assets/icons/development/linkedin-light.svg"
          alt="linkedin"
      >
    </a>
    <a
        href="https://twitter.com/AriCompany/"
        class="social-item"
        target="_blank"
    >
      <img
          src="@/assets/icons/development/twitter-light.svg"
          alt="twitter"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: "FooterSocials"
}
</script>

<style lang="scss" scoped>
.footer__social {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .social-item {
    width: 40px;
    text-align: right;
  }
}
</style>