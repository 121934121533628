










import Vue from 'vue'
export default Vue.extend({
  name: 'MobileMenuContacts',

  data () {
    return {
      langs: [
        { id: 1, title: 'En' },
      ],
      selectedLang: 'En'
    }
  },

  methods: {
    selectLanguage (e: string): void {
      this.selectedLang = e
    }
  }
})
