export default [
   {
      id: 1,
      src: require('../../assets/img/development/portfolio/Cryptocurrency.webp'),
      alt: 'crypto', isActive: false, isNext: false,
      category: 'crypto',
      content: {
         title: 'Spot cryptocurrency exchange',
         text: 'This cryptocurrency exchange web service allows customers to exchange cryptocurrency or withdraw it to their bank account. Any user can register an account and start using it.',
         hours: 360,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/react-dark.svg'), alt: 'React'},
            { id: 2, src: require('../../assets/icons/development/python-dark.svg'), alt: 'Python'},
            { id: 3, src: require('../../assets/icons/development/postgre-dark.svg'), alt: 'Postgre'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/react-logo.svg'), alt: 'React' },
            { id: 2, src: require('../../assets/icons/development/techs/python-logo.svg'), alt: 'Python' },
            { id: 3, src: require('../../assets/icons/development/techs/postgre-logo.svg'), alt: 'PHP' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'CEO',
            name: 'Lincoln A.'
         }
      }
   },
   {
      id: 2, src: require('../../assets/img/development/portfolio/Corporate.webp'),
      alt: 'Corporate', isActive: false, isNext: false,
      category: 'corporate',
      content: {
         title: 'Digital agency website',
         text: 'It\'s a corporate website created for a digital agency in Europe.',
         hours: 210,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/react-dark.svg'), alt: 'React'},
            { id: 2, src: require('../../assets/icons/development/node-dark.svg'), alt: 'Node'},
            { id: 3, src: require('../../assets/icons/development/mongo-dark.svg'), alt: 'Mongo'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/react-logo.svg'), alt: 'React.js' },
            { id: 2, src: require('../../assets/icons/development/techs/nodejs.svg'), alt: 'Node.js' },
            { id: 3, src: require('../../assets/icons/development/techs/mongo-logo.svg'), alt: 'Mongo' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'CTO',
            name: 'Roger B.'
         }
      }
   },
   {
      id: 3, src: require('../../assets/img/development/portfolio/Car.webp'),
      alt: 'Platform for car sales', isActive: false, isNext: false,
      category: 'ecommerce',
      content: {
         title: 'Car selling platform',
         text: 'This car selling platform allows customers to buy and sell cars quickly. Additionally, this platform includes videos of people doing reviews on vehicles, both professional and amateur.',
         hours: 190,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/vue-dark.svg'), alt: 'Vue'},
            { id: 2, src: require('../../assets/icons/development/node-dark.svg'), alt: 'Node'},
            { id: 3, src: require('../../assets/icons/development/mongo-dark.svg'), alt: 'Mongo'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/vue.svg'), alt: 'Vue.js' },
            { id: 2, src: require('../../assets/icons/development/techs/nodejs.svg'), alt: 'Node.js' },
            { id: 3, src: require('../../assets/icons/development/techs/mongo-logo.svg'), alt: 'Mongo' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'Product Owner',
            name: 'Cooper S.'
         }
      }
   },
   {
      id: 4, src: require('../../assets/img/development/portfolio/BioSmartex.webp'),
      alt: 'BioSmartex', isActive: false, isNext: false,
      category: 'corporate',
      content: {
         title: 'bioSMARTEX',
         text: 'It\'s a corporate website created for a company selling drying complexes, granulation lines, and biomass briquettes. ',
         hours: 50,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/wordpress-seeklogo.com 2.svg'), alt: 'Wordpress'},
            { id: 2, src: require('../../assets/icons/development/php-seeklogo.com 2.svg'), alt: 'PHP'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/wordpress.svg'), alt: 'Wordpress' },
            { id: 2, src: require('../../assets/icons/development/techs/php.svg'), alt: 'PHP' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'CEO',
            name: 'Mitsura I.I.'
         }
      }
   },
   {
      id: 5, src: require('../../assets/img/development/portfolio/Dating.webp'),
      alt: 'Dating', isActive: true, isNext: false,
      category: 'dating',
      content: {
         title: 'Online dating',
         text: 'This online dating platform allows users to meet new people, start dating, and even get married. It’s the right place to find your perfect match and change life for the better.',
         hours: 390,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/vue-dark.svg'), alt: 'Vue'},
            { id: 2, src: require('../../assets/icons/development/python-dark.svg'), alt: 'Python'},
            { id: 3, src: require('../../assets/icons/development/postgre-dark.svg'), alt: 'Postgre'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/vue.svg'), alt: 'Vue.js' },
            { id: 2, src: require('../../assets/icons/development/techs/python-logo.svg'), alt: 'Python' },
            { id: 3, src: require('../../assets/icons/development/techs/postgre-logo.svg'), alt: 'Postgre' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'Product Owner',
            name: 'Liza B.'
         }
      }
   },
   {
      id: 6, src: require('../../assets/img/development/portfolio/E-commerce.webp'),
      alt: 'E-commerce', isActive: false, isNext: true,
      category: 'ecommerce',
      content: {
         title: 'Sell-anything marketplace',
         text: 'This marketplace allows people to sell their goods online. It includes different categories, from clothes & shoes, travel equipment to kitchen accessories.',
         hours: 180,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/vue-dark.svg'), alt: 'Vue'},
            { id: 2, src: require('../../assets/icons/development/python-dark.svg'), alt: 'Python'},
            { id: 3, src: require('../../assets/icons/development/postgre-dark.svg'), alt: 'Postgre'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/vue.svg'), alt: 'Vue.js' },
            { id: 2, src: require('../../assets/icons/development/techs/python-logo.svg'), alt: 'Python' },
            { id: 3, src: require('../../assets/icons/development/techs/postgre-logo.svg'), alt: 'Postgre' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'CEO',
            name: 'Dulce D.'
         }
      }
   },
   {
      id: 7, src: require('../../assets/img/development/portfolio/Medicine.webp'),
      alt: 'Medicine', isActive: false, isNext: false,
      category: 'health',
      content: {
         title: 'Healthcare website',
         text: 'It\'s a corporate website created for a group of doctors in Europe.',
         hours: 250,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/vue-dark.svg'), alt: 'Vue'},
            { id: 2, src: require('../../assets/icons/development/node-dark.svg'), alt: 'Node'},
            { id: 3, src: require('../../assets/icons/development/mysql-dark.svg'), alt: 'Mysql'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/vue.svg'), alt: 'Vue.js' },
            { id: 2, src: require('../../assets/icons/development/techs/nodejs.svg'), alt: 'Node.js' },
            { id: 3, src: require('../../assets/icons/development/techs/mysql-logo.svg'), alt: 'Mysql' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'Co-Founder & Product Owner',
            name: 'Omar P.'
         }
      }
   },
   {
      id: 8, src: require('../../assets/img/development/portfolio/Mirnishii.webp'),
      alt: 'Mirnychyy', isActive: false, isNext: false,
      category: 'corporate',
      content: {
         title: 'Mirnychyy',
         text: 'It\'s a corporate website created for a company dealing with geology, geodesy, and aerial photography.',
         hours: 80,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/wordpress-seeklogo.com 2.svg'), alt: 'Wordpress'},
            { id: 2, src: require('../../assets/icons/development/php-seeklogo.com 2.svg'), alt: 'PHP'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/wordpress.svg'), alt: 'Wordpress' },
            { id: 2, src: require('../../assets/icons/development/techs/php.svg'), alt: 'PHP' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: '',
            name: 'Mirnychy LLC'
         }
      }
   },
   {
      id: 9, src: require('../../assets/img/development/portfolio/Travel.webp'),
      alt: 'Real-estate platform', isActive: false, isNext: false,
      category: 'real-estate',
      content: {
         title: 'Real-estate platform',
         text: 'This real-estate platform allows users to rent an apartment or house in different parts of Europe or sell their property.',
         hours: 140,
         technologies: [
            { id: 1, src: require('../../assets/icons/development/php-seeklogo.com 2.svg'), alt: 'PHP'},
            { id: 2, src: require('../../assets/icons/development/laravel-dark.svg'), alt: 'laravel'},
            { id: 3, src: require('../../assets/icons/development/vue-dark.svg'), alt: 'Vue'},
            { id: 4, src: require('../../assets/icons/development/mysql-dark.svg'), alt: 'Mysql'},
         ],
         platforms: [
            { id: 1, src: require('../../assets/icons/development/monitor.svg'), alt: 'Desk'},
            { id: 2, src: require('../../assets/icons/development/IOS_wordmark_(2017) 2.svg'), alt: 'IOS'},
            { id: 3, src: require('../../assets/icons/development/Android_symbol_green_RGB 2.svg'), alt: 'Android'},
         ],
         technologiesMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/php.svg'), alt: 'PHP' },
            { id: 2, src: require('../../assets/icons/development/techs/laravel.svg'), alt: 'Laravel' },
            { id: 3, src: require('../../assets/icons/development/techs/vue.svg'), alt: 'Vue.js' },
            { id: 4, src: require('../../assets/icons/development/techs/mysql-logo.svg'), alt: 'MySql' },
         ],
         platformsMobile: [
            { id: 1, src: require('../../assets/icons/development/techs/architecture.svg'), alt: 'Desktop' },
            { id: 2, src: require('../../assets/icons/development/techs/ios.svg'), alt: 'IOS' },
            { id: 3, src: require('../../assets/icons/development/techs/android.svg'), alt: 'Android' },
         ],
         author: {
            position: 'CMO.NDA',
            name: 'Terry C.'
         }
      }
   },
];
