<template>
  <v-row class="section-count-item" no-gutters>
    <v-col class="text-right">
      <div class="count-number gradient-text">{{ count }}</div>
    </v-col>
    <v-col class="d-flex align-center">
      <h4 class="count-text">
        <span>{{ text1 }}</span>
        <span>{{ text2 }}</span>
      </h4>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SectionCountItem",
  props: {
    count: { type: Number, required: true },
    text1: { type: String, required: true },
    text2: { type: String, required: true },
  }
}
</script>

<style scoped lang="scss">
.section-count-item {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 500;

  .count-number {
    display: inline-block;
    font-size: 70px;
    font-weight: bold;
    margin-right: 14px;
  }

  .count-text {
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
  }

  .count-text span {
    flex-basis: 100%;
  }

  /**
  * Media
   */
  @media (min-width: $mobile-large) and (max-width: $pad-small) {
    .count-number {
      font-size: 56px;
      margin-right: 4px;
    }
    .count-text {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media (min-width: $desk-large) {
    .count-number {
      font-size: 90px;
    }
    .count-text {
      line-height: 28px;
    }
  }
}
</style>