




























































import Vue from 'vue'
import MobileMenu from './MobileMenu/MobileMenu.vue'

export default Vue.extend({
  components: { MobileMenu },
  name: 'AppBar',

  data () {
    return {
      scrollPos: 0,
      langs: [
        { id: 1, title: 'En' },
        { id: 1, title: 'Ru' },
        // { id: 1, title: 'Ua' }
      ],
      selectedLang: 'En',
    }
  },

  computed: {
    banner () {
      return this.$store.getters.banner
    },
    stickyClass () {
      if (this.banner && this.scrollPos > 100) {
        return 'app-bar-banner-fixed'
      }
      if (!this.banner && this.scrollPos > 0) {
        return 'app-bar-fixed'
      }
      return ''
    }
  },

  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },

  methods: {
    updateScroll() {
      this.scrollPos = window.scrollY
    },
    selectLanguage (e: string): void {
      this.selectedLang = e
    },
    openMobileMenu () {
      this.$store.dispatch('UI/mobileMenu', true)
    }
  }
})
