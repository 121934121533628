import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-faq"},[_c('div',{staticClass:"section-faq__bg_faq"},[_vm._v("FAQ")]),_c(VContainer,{staticClass:"section-faq__wrapper"},[_c(VRow,{staticClass:"section-faq__title"},[_c('h3',{staticClass:"light-text"},[_vm._v("FAQ")])]),_c(VRow,{attrs:{"justify":"center"}},[_c(VExpansionPanels,{attrs:{"accordion":""}},_vm._l((_vm.startItems),function(item){return _c(VExpansionPanel,{key:item.id,staticClass:"py-4",staticStyle:{"background":"transparent","color":"white"}},[_c(VExpansionPanelHeader,{attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex no-wrap"},[_c('div',{staticClass:"mr-5"},[(open)?_c('img',{attrs:{"src":require("@/assets/icons/minus.svg"),"alt":"open","width":"20","height":"20"}}):_c('img',{attrs:{"src":require("@/assets/icons/plus.svg"),"alt":"open","width":"20","height":"20"}})]),_c('h5',{staticClass:"header light-text"},[_vm._v(_vm._s(item.title))])])]}}],null,true)}),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c(VRow,{staticClass:"section-faq__more pt-9"},[_c(VCol,[_c(VRow,{staticClass:"section-faq__more_text d-flex justify-center"},[(!_vm.showFull)?_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.showFull = true}}},[_vm._v("Show more")]):_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.showFull = false}}},[_vm._v("Show less")])],1),_c(VRow,{staticClass:"section-faq__more_icon d-flex justify-center"},[(!_vm.showFull)?_c('img',{attrs:{"src":require("@/assets/icons/development/arrows.svg"),"alt":"arrows"}}):_c('img',{staticClass:"reversed-arrows",attrs:{"src":require("@/assets/icons/development/arrows.svg"),"alt":"arrows"}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }