




































import Vue from 'vue'
import { mapGetters } from "vuex"

import developmentTextData from './Data/developmentTextData'
import faqItemsData from './Data/faqItemsData'

import AnchorMenu from '@/components/particles/AnchorMenu.vue'
import SectionHero from './Sections/Hero/SectionHero.vue'
import SectionTechs from './Sections/Techs/SectionTechs.vue'
import SectionCount from './Sections/Count/SectionCount.vue'
import CallToActions from '@/components/particles/CallToActions/CallToActions.vue'
import SectionDevelopment from './Sections/Development/SectionDevelopment.vue'
import MainCarousel from '@/components/MainCarousel/MainCarousel.vue'
import PortfolioCarousel from '@/components/particles/PortfolioCarousel/PortfolioCarousel.vue'
import SectionMobileApp from './Sections/MobileApp/SectionMobileApp.vue'
import MobileAppCarousel from '@/components/particles/MobileAppCarousel/MobileAppCarousel.vue'
import SectionChatbot from './Sections/Chatbot/SectionChatbot.vue'
import SectionFreelances from './Sections/Freelances/SectionFreelances.vue'
import SectionFaq from './Sections/Faq/SectionFaq.vue'
import Contact from '@/components/particles/Contact/Contact.vue'
import SectionQuotes from './Sections/Quotes/SectionQuotes.vue'

export default Vue.extend({
  name: 'DevelopmentPage',
  metaInfo: {
    meta: [
      {
        name: 'title',
        content: 'SaaS Product & Software Development - ARI.company'
      },
      {
        name: 'description',
        content: 'Our expertise covers all popular technologies and frameworks and advances to innovative technological trends.'
      }
    ]
  },
  components: {
      AnchorMenu,
      SectionHero,
      SectionTechs,
      SectionCount,
      CallToActions,
      SectionDevelopment,
      MainCarousel,
      PortfolioCarousel,
      SectionMobileApp,
      MobileAppCarousel,
      SectionChatbot,
      SectionFreelances,
      SectionFaq,
      Contact,
      SectionQuotes

      // AnchorMenu: defineAsyncComponent(() => import('@/components/particles/AnchorMenu.vue')),
      // SectionHero: defineAsyncComponent(() => import('./Sections/Hero/SectionHero.vue')),
      // SectionTechs: defineAsyncComponent(() => import('./Sections/Techs/SectionTechs.vue')),
      // SectionCount: defineAsyncComponent(() => import('./Sections/Count/SectionCount.vue')),
      // CallToActions: defineAsyncComponent(() => import('@/components/particles/CallToActions/CallToActions.vue')),
      // SectionDevelopment: defineAsyncComponent(() => import('./Sections/Development/SectionDevelopment.vue')),
      // PortfolioCarousel: defineAsyncComponent(() => import('@/components/particles/PortfolioCarousel/PortfolioCarousel.vue')),
      // SectionMobileApp: defineAsyncComponent(() => import('./Sections/MobileApp/SectionMobileApp.vue')),
      // MobileAppCarousel: defineAsyncComponent(() => import('@/components/particles/MobileAppCarousel/MobileAppCarousel.vue')),
      // SectionChatbot: defineAsyncComponent(() => import('./Sections/Chatbot/SectionChatbot.vue')),
      // SectionFreelances: defineAsyncComponent(() => import('./Sections/Freelances/SectionFreelances.vue')),
      // SectionFaq: defineAsyncComponent(() => import('./Sections/Faq/SectionFaq.vue')),
      // Contact: defineAsyncComponent(() => import('@/components/particles/Contact/Contact.vue')),
      // SectionQuotes: defineAsyncComponent(() => import('./Sections/Quotes/SectionQuotes.vue')),
  },

  data () {
    return {
      anchorLinks: [
        { id: 1, title: 'Saas', link: '#Develop_Your_Business_Success_with_Us', isActive: true },
        { id: 2, title: 'Web Development', link: '#web-development', isActive: false },
        { id: 3, title: 'Mobile App Development', link: '#Mobile_App_Development', isActive: false },
        { id: 4, title: 'Chatbot Development', link: '#Chatbot_Development', isActive: false },
      ],
      imgDevelopment: {
        src: require('@/assets/img/development/imac.webp'),
        alt: 'IMac'
      },
      developmentText: developmentTextData,
      faqItems: faqItemsData
    }
  },

  computed: mapGetters({
    portfolioItems: 'portfolioItems',
    portfolioMobileApps: 'portfolioMobileApps'
  })
})
