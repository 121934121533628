<template>
  <div class="policy-layout">
    <AppBar />
    <slot></slot>
    <Footer style="flex: 0 0 auto;"/>
  </div>
</template>

<script>
import AppBar from "../components/particles/AppBar"
import Footer from "../components/particles/Footer/Footer"

export default {
  name: "PolicyLayout",
  components: { Footer, AppBar }
}
</script>

<style lang="scss" scoped>
.policy-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
</style>