








import Vue from 'vue'
import AppBar from '@/components/particles/AppBar.vue'
import Footer from '@/components/particles/Footer/Footer.vue'

export default Vue.extend({
  name: 'MainLayout',
  components: {
    AppBar,
    Footer
  }
})
