











import Vue from 'vue';
import MainLayout from './layouts/MainLayout.vue'
import PolicyLayout from './layouts/PolicyLayout.vue'
import Banner from "@/components/particles/Banner.vue"

export default Vue.extend({
  name: 'App',
  components: {
    Banner,
    MainLayout,
    PolicyLayout
  },
  data () {
    return {
      scrollPos: 0
    }
  },
  computed: {
    layout(): string {
      return (this as any).$route.meta.layout || 'main-layout'
    },
  },
  methods: {
    updateScroll() {
      this.scrollPos = window.scrollY
    },
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },
});
