




















import Vue from 'vue'
import { Slide } from 'vue-carousel-3d'
import MainCarousel from '@/components/MainCarousel/MainCarousel.vue'
import MobileAppCarouselItem from './MobileAppCarouselItem.vue'
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'MobileAppCarousel',
  components: {
    Slide,
    MainCarousel,
    MobileAppCarouselItem,
  },

  props: {
    items: { required: true },
    carouselOptions: { required: false },
  },

  data () {
    return {
      screenSizes: {
        small: {
          width: 323,
          height: 648,
          spacing: 400
        },
        medium: {
          width: 897,
          height: 670,
          spacing: 450
        },
        large: {
          width: 937,
          height: 670,
          spacing: 530
        }
      }
    }
  },
})
